.thank-you-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fff;
}

.check-mark-animation {
  width: 25%;
  margin-bottom: 2rem;
}

.fw-400{
  font-weight: 400;
}
.font-family-lx{
  font-family: fangsong;
}
