.main-container {
  position: relative;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 10px;
}
.main-rectangle {
  position: relative;
  /* overflow: hidden; */
  display: flex;
  align-items: center;
  width: 95%;
  height: 50%;
  border-radius: 13px;
  background: linear-gradient(
    90deg,
    #a52c31 4%,
    #ffe500 33%,
    #0c9019 52.84%,
    #ffe500 65%,
    #a52c31 100%
  );
}
.inner-container {
  display: inline-block;
  box-sizing: border-box;
  display: grid;

  /* background-color: greenyellow; */
  position: absolute;
  width: 8%;
  left: 100%;
}
.ellipse {
  display: flex;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: #ffffff;
  border: 3px solid #a54f55;
  border-radius: 30px;
}
.inner-rectangle {
  margin-top: -14%;
  margin-left: -8%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: inline-block;
  width: 72px;
  height: 20px;
  background: #a54f55;
  z-index: 1000;
}
/* styles.css */
.no-page-break {
  page-break-inside: avoid;
}
